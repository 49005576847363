import React from "react";

const AccountBook = () => {
    return(
        <div>
            <h1>가계부</h1>
        </div>
    );
};

export default AccountBook;